<template>
    <div style="height: 100%">
        <title-tap
            v-if="!showFollowPage && !showClientFollow"
            :active-name="activeName"
            :tab-data="tabData"
            @selectInfo="selectInfo"
        />
        <div v-if="!showFollowPage && !showClientFollow">
            <el-card class="main">
                <!-- 搜索区域 -->
                <div class="search flex-sb">
                    <div class="top-search">
                        <el-input
                            v-model="findForm.keyWord"
                            clearable
                            size="small"
                            placeholder="请输入姓名/手机号"
                            @keypress.native.enter="findData"
                            @clear="findData"
                        />
                        <!-- <i class="el-icon-search"></i> -->
                        <div class="icon-area" @click="findData">
                            <img width="20" height="20" src="../../assets/common/search.png" alt />
                        </div>
                    </div>
                    <div class="flex-sb right-time">
                        <div>{{ timeName }}</div>
                        <div>
                            <el-radio-group v-model="timeTabs" size="small" @change="changeTime">
                                <el-radio-button label="0">全部</el-radio-button>
                                <el-radio-button label="1">今日</el-radio-button>
                                <el-radio-button label="2">昨日</el-radio-button>
                                <el-radio-button label="3">最近一周</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div>
                            <el-date-picker
                                v-model="valuetime"
                                size="small"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="timestamp"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="time"
                            />
                        </div>
                    </div>
                </div>
                <!-- 表格区域 -->
                <div>
                    <!-- 详情弹框 -->
                    <detail-dialog
                        ref="showDialog"
                        @refresh="refresh"
                        @followPage="followPage"
                        @cilentFolloePage="cilentFolloePage"
                        @colseDialog="colseDialog"
                    />
                    <!-- 系统分配的 -->
                    <distribution
                        v-if="activeName == 0"
                        ref="distribution"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />

                    <!-- 我导入的 -->
                    <import-forme
                        v-if="activeName == 1"
                        ref="importantForme"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                    <!-- 我入库的 -->
                    <warehouseIn
                        v-if="activeName == 2"
                        ref="warehouseIn"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                    <!-- 我超时的 -->
                    <out-of-time
                        v-if="activeName == 3"
                        ref="outOfTime"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                    <!-- 我确认无效的 -->
                    <invalid
                        v-if="activeName == 4"
                        ref="invalid"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                    <!-- 转给我的 -->
                    <transferMe
                        v-if="activeName == 5"
                        ref="transferMe"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                    <!-- 我转移的 -->
                    <transferOther
                        v-if="activeName == 6"
                        ref="transferOther"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                    <!-- 我抓取的 -->
                    <crawl
                        v-if="activeName == 7"
                        ref="crawl"
                        :time="valuetime"
                        :key-word="keyWord"
                        @showDetail="showDetail"
                    />
                </div>
            </el-card>
        </div>
        <!-- 跟进组件 -->
        <follow v-show="showFollowPage" ref="follow" @closeFollow="closeFollow" />

        <client-follow
            v-show="showClientFollow"
            ref="clientFollowPage"
            @closeFollow="closeClientFollow"
        />
    </div>
</template>

<script>
import resize from '@/mixins/resize';
export default {
    mixins: [resize],
    data() {
        return {
            tabData: [
                '系统分配的',
                '导入给我的',
                '我入库的',
                '我超时的',
                '我确认无效的',
                '转给我的',
                '我转移的',
                '我抓取的',
            ],
            activeName: 0,
            timeTabs: 0,
            keyWord: '',
            valuetime: '',
            showDialog: false,
            findForm: {
                keyWord: '',
                valuetime: '',
            }, //搜索查找数据
            showFollowPage: false,
            timeName: '系统分配的时间',
            showClientFollow: false,
            show: false,
        };
    },
    components: {
        'title-tap': () => import('../../components/templates/title-tap'),
        crawl: () => import('./recordComponents/crawl'),
        distribution: () => import('./recordComponents/distribution'),
        invalid: () => import('./recordComponents/invalid'),
        'out-of-time': () => import('./recordComponents/out-of-time'),
        importForme: () => import('./recordComponents/importForMe'),
        transferMe: () => import('./recordComponents/transferMe'),
        transferOther: () => import('./recordComponents/transferOther'),
        warehouseIn: () => import('./recordComponents/warehouseIn'),
        detailDialog: () => import('./recordComponents/detail-dialog'),
        follow: () => import('./follow'),
        clientFollow: () => import('../client/follow'),
    },
    methods: {
        selectInfo(val) {
            this.activeName = val.index;
            (this.keyWord = ''), (this.valuetime = '');
            this.findForm.keyWord = '';
            this.timeTabs = 0;
            if (this.activeName == 0) {
                this.timeName = '系统分配的时间';
            } else if (this.activeName == 1) {
                this.timeName = '导入的时间';
            } else if (this.activeName == 2) {
                this.timeName = '我入库的时间';
            } else if (this.activeName == 3) {
                this.timeName = '我超时的时间';
            } else if (this.activeName == 4) {
                this.timeName = '我确认无效的时间';
            } else if (this.activeName == 5) {
                this.timeName = '转给我的时间';
            } else if (this.activeName == 6) {
                this.timeName = '我转移的时间';
            } else if (this.activeName == 7) {
                this.timeName = '我抓取的时间';
            }
        },
        changeTime() {
            console.log(this.timeTabs);
            // 起始时间
            const start = new Date(new Date(new Date().toLocaleDateString()));
            // 结束时间
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            console.log('this.valuetime', this.valuetime);
            this.findData();
        },
        time(val) {
            console.log(val);
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
            } else {
                this.timeTabs = '';
                this.valuetime = val;
            }
            this.findData();
        },
        findData() {
            console.log('this.keyword', this.findForm.keyWord);
            this.keyWord = this.findForm.keyWord;
        },
        showDetail(showOrnot, info) {
            let detailId = info.id;
            // this.showDialog = showOrnot;
            this.$refs.showDialog.init(detailId);
            // this.$nextTick(() => {
            //   console.log(this);
            //   this.show = true;
            // });
        },
        colseDialog(val) {
            this.show = val;
        },
        refresh() {
            // this.$refs.distribution.xr();
            // this.$refs.warehouseIn.xr();
            // this.$refs.outOfTime.xr();
            // this.$refs.invalid.xr();
            // this.$refs.transferMe.xr();
            // this.$refs.transferOther.xr();
            // this.$refs.crawl.xr();
        },
        followPage(row) {
            console.log(row);
            this.showFollowPage = true;
            this.$refs.follow.init(row);
        },
        closeFollow() {
            this.showFollowPage = false;
        },
        // 转跳客户跟进页面
        cilentFolloePage(val) {
            this.showClientFollow = true;
            this.$nextTick(() => {
                this.$refs.clientFollowPage.init(val);
            });
        },
        closeClientFollow() {
            this.showClientFollow = false;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-table thead tr th {
    background-color: #f4f6f8;
    color: #909399;
}
.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main {
    height: calc(100vh - 145px);
    margin: 10px 20px 20px 20px;
    .search {
        .top-search {
            /deep/ .el-input__clear {
                margin-right: 23px;
            }
            position: relative;
            .icon-area {
                position: absolute;
                right: 5px;
                top: 8px;
                border-left: 1px solid #ccc;
                padding-left: 5px;
                cursor: pointer;
            }
        }
        .right-time {
            div:not(:last-child) {
                margin-right: 10px;
            }
            /deep/
                .el-radio-button__orig-radio:checked
                + .el-radio-button__inner {
                color: #4086ec;
                background-color: #ecf3fd;
                box-shadow: 0 0px 0px #ccc;
                border-color: #dcdfe6;
            }
            /deep/ .el-radio-button__inner {
                color: #aaa;
            }
        }
    }
}
</style>
